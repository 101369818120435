.tab-panel {
  padding: 1rem 0;

  &:not(.tab-panel-active) {
    display: none;
  }
}

.tab {

  background-color: transparent;
  border: none;
  font-weight: bold;
  padding: 12px 16px;
  transition: box-shadow 100ms linear;

  &:not(:disabled):hover {
    box-shadow: inset 0 -2px 0 0 var(--dark-accent);
    cursor: pointer;
  }

  &.tab-active {
    box-shadow: inset 0 -2px 0 0 var(--dark-shades);
    background-color: var(--light-accent-600);
  }

  @media (max-width: 440px) {
    font-size: 13px;
    padding: 12px 6px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
    padding: 12px 4px;
  }

}

.tablist {
  background: var(--light-accent);
  border-bottom: 1px #ccc solid;
  position: sticky;
  text-align: center;
  top: 0;
  white-space: nowrap;
  z-index: 500;
}